<template>
  <div class="form">
    <div class="wrapper illustration illustration_percent">
      <Backlink title="detail" service="mortgage" backlink="/mortgage/already-sold" />
      <div class="content">
        <div class="content__container">
          <form @submit.prevent>
            <p class="promo promo_blocks">{{ "s2p8_text_1" | localize }}</p>
            <v-radio-group
              v-model="sell_during"
              class="row"
              :class="{
                invalid: $v.sell_during.$dirty && !$v.sell_during.required,
              }"
              ref="sell_during"
              row
              @change="
                $v.sell_during.$touch();
                checkForm(false);
              "
            >
              <v-radio
                :label="'s2p8_radio_1' | localize"
                value="1"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
              <v-radio
                :label="'s2p8_radio_2' | localize"
                value="2"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
              <v-radio
                :label="'s2p8_radio_3' | localize"
                value="3"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
            </v-radio-group>

            <p class="question">{{ "s2p8_text_2" | localize }}</p>
            <v-radio-group
              v-model="valuation"
              class="row"
              :class="{
                invalid: $v.valuation.$dirty && !$v.valuation.required,
              }"
              ref="valuation"
              row
              @change="
                $v.valuation.$touch();
                checkForm(false);
              "
            >
              <v-radio
                :label="'s2p8_radio_4' | localize"
                value="1"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
              <v-radio
                :label="'s2p8_radio_5' | localize"
                value="2"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
            </v-radio-group>

            <div v-if="valuation == 1">
              <div class="form__group">
                <v-text-field
                  v-model="valuation_price"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @input="checkForm($v.valuation_price)"
                  @keypress="onKeyPressHandler"
                  @keyup="
                    onKeyUpPriceHandler('valuation_price', valuation_price)
                  "
                  :label="'s2p8_text_2' | localize"
                  :placeholder="'c_s1p1_placeholder' | localize"
                  append-icon="icon-₪"
                  ref="valuation_price"
                  outlined
                ></v-text-field>
              </div>
            </div>

            <p class="question">{{ "s2p8_text_3" | localize }}</p>
            <v-radio-group
              v-model="deposit"
              class="row"
              :class="{ invalid: $v.deposit.$dirty && !$v.deposit.required }"
              ref="deposit"
              row
              @change="
                $v.deposit.$touch();
                checkForm(false);
              "
            >
              <v-radio
                :label="'s2p8_radio_6' | localize"
                value="1"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
              <v-radio
                :label="'s2p8_radio_7' | localize"
                value="2"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
            </v-radio-group>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'

const mortgage = VueCookies.get('mortgage') || {}

export default {
  name: 'Mortgage-sell-during',
  components: { Backlink },
  mixins: [formMixin, commonMixin, numberMixin, saveMixin],
  data: () => ({
    sell_during: mortgage.sell_during || null,
    valuation: mortgage.valuation || null,
    valuation_price: mortgage.valuation_price || null,
    deposit: mortgage.deposit || null,
    nextlink: null
  }),
  validations: {
    sell_during: { required },
    valuation: { required },
    deposit: { required }
  },
  created () {
    this.valuation_price = mortgage.valuation_price ? this.formatNumber(mortgage.valuation_price) : null
  },
  methods: {
    submitHandler () {
      this.valuation_price = this.parseNumber(this.valuation_price)

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      mortgage.sell_during = this.sell_during
      mortgage.valuation = this.valuation
      mortgage.valuation_price = this.valuation_price
      mortgage.deposit = this.deposit

      this.$cookies.set('mortgage', mortgage)

      // Сброс следующих шагов линии действий
      this.dropSteps('mortgage', this.$router.currentRoute.path, '/mortgage/help')

      // Переход в зависимости от выбора
      if (this.sell_during === '2') {
        this.nextlink = '/mortgage/help'
      } else {
        this.nextlink = '/mortgage/old-realty-price-not-sold'
        this.addMissedSteps('mortgage', ['/mortgage/help'])
      }

      if (this.valuation === '1' && this.valuation_price > 0) {
        this.saveServiceInfo('mortgage', this.nextlink, { potential_capital: this.valuation_price })
      } else {
        this.$router.push(this.nextlink)
      }
    }
  }
}
</script>
